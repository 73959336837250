@import url("https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css");

:root {
  --off-black: #172B4D;
  --text-white: #ffffff;
  --text-blue-strong: #2684FF;
  --text-blue: #005CC9;
  --text-blue-shade: #172B4D;
  --text-blue-500: #003CB3;
  --text-black: #000000;
  --text-gray: #606266;
  --primary-button: #0052CC;

  --placeholder: #a4a5ac;
  --disabled: #e4e4e4;
  --label: #172B4D;
  --line: #d4d9e1;
  --background4: #2684FF;
  --background3: #003CB2;
  --background2: #fff;
  --background1: #005CC9;

  --h1: 76px;
  --h2: 56px;
  --h3: 40px;
  --subtitle1: 42px;
  --subtitle2: 36px;
  --subtitle3: 28px;
  --body1: 24px;
  --body2: 20px;
  --caption: 14px;
  --button: 20px;
  --overline: 16px;

  --padding: 4vw 7vw;

  --width-screen: 100%;

  --mobile: 415px;
  --taplet: 768px;
}

* {
  box-sizing: border-box;
}

html {

  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'IBM', 'Manrope';
  font-size: var(--body2);
  line-height: 1.5;
  color: var(--text-blue-shade);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin: 0;
  font-size: var(--h1);
}

h2 {
  margin: 0;
  font-size: var(--h2);
}

h3 {
  margin: 0;
  font-size: var(--h3);
}

p {
  margin: 0;
}

.bold {
  font-weight: bold;
}

.blue-text {
  color: #005CC9;
}

.clickable {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 56px;
  }

  h2 {
    font-size: 30px;
  }

  .md-none {
    display: none;
  }


}

@media screen and (max-width: 415px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 26px;
  }


  .sm-none {
    display: none;
  }
}