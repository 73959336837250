@font-face {
  font-family: 'IBM';
  src: url('../fonts/IBM/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IBM';
  src: url('../fonts/IBM/IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'IBM';
  src: url('../fonts/IBM/IBMPlexSans-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Manrope-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Manrope-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('../fonts/Manrope/Manrope-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}